"use client"

import { MobileUserProfile } from "@/components/layout/appbar/user-profile/MobileUserProfile"
import { UserProfile } from "@/components/layout/appbar/user-profile/UserProfile"
import { ImageButton } from "@/components/ui/button/ImageButton"
import LoginButton from "@/components/ui/button/LoginButton"
import { useAuth } from "@/hooks/useAuth"
import useScreenSize from "@/hooks/useScreenSize"
import { ROUTES } from "@/interfaces/constants/routes"
import { ProfileOutput } from "@/interfaces/profile/ProfileOutput"
import { useUserProfileStore } from "@/stores/ui/useUserProfileStore"
import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import { Box, Button, Container, Fade, Stack, useTheme } from "@mui/material"
import { useRouter } from "next/navigation"
import { useEffect } from "react"

import HeroSection from "../HeroSection"
import { HomeHeaderLinks } from "./HomeHeaderLinks"
import HomeHeaderMobileLinks from "./HomeHeaderMobileLinks"

interface BaseHeaderProps {
  isHome?: boolean
  profile?: ProfileOutput[]
}

export default function BaseHeader({ isHome = false, profile }: BaseHeaderProps) {
  const { clicked, setClicked } = useUserProfileStore()
  const theme = useTheme()
  const { isUpMd } = useScreenSize()
  const router = useRouter()
  const isLoggedIn = useAuth()

  const handleScrollToSection = () => {
    const section = document.getElementById("company-tutorial")
    if (section) {
      section.scrollIntoView({ behavior: "smooth" })
    }
  }

  useEffect(() => {
    setClicked(false)
  }, [isUpMd, setClicked])

  const handleLogoClick = () => {
    router.push(ROUTES.HOME)
  }

  const renderProfileSection = () => {
    if (isLoggedIn) {
      return (
        <Box>
          <UserProfile />
          <Fade in={clicked}>
            <div>
              <MobileUserProfile />
            </div>
          </Fade>
        </Box>
      )
    }
    return commonNavbar
  }

  const commonNavbar = (
    <Stack
      component="nav"
      direction="row"
      spacing={isHome ? 2 : 0}
      color="white"
      sx={{
        my: isHome ? 2 : 0,
        ml: isHome ? 2 : 0,
        justifyContent: "center",
        height: "2.25rem",
      }}
    >
      {isHome && (
        <Button variant="text" onClick={handleScrollToSection} sx={{ color: "white" }}>
          {"PARA EMPRESAS"}
        </Button>
      )}
      {!isLoggedIn && <LoginButton />}
    </Stack>
  )

  const commonAppBar = (
    <Box
      sx={{
        position: { sm: "fixed" },
        top: 0,
        height: theme.appHeader.height,
        width: "100vw",
        backgroundColor: "blueMsGov.main",
        zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="start">
          <ImageButton
            onClick={handleLogoClick}
            imageSrc={getAssetsPath() + "/img/logo-ms-gov.svg"}
            alt="logo do governo de Mato Grosso do Sul"
            sx={{
              width: "auto",
              height: { xs: "80px", sm: "90px" },
              padding: "0 0 8px 0",
            }}
            usePriority
          />
          {renderProfileSection()}
        </Stack>
      </Container>
    </Box>
  )

  if (isHome) {
    return (
      <header>
        <HomeHeaderMobileLinks />
        <HomeHeaderLinks />
        <Box
          sx={{
            backgroundColor: "blueMsGov.main",
            px: 2,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              maxWidth: "lg",
              margin: "0 auto",
            }}
          >
            <ImageButton
              onClick={handleLogoClick}
              imageSrc={getAssetsPath() + "/img/logo-ms-gov.svg"}
              alt="logo do governo de Mato Grosso do Sul"
              sx={{
                width: "auto",
                height: { xs: "80px", sm: "90px" },
                padding: "0 0 16px 0",
              }}
              usePriority
            />
            {renderProfileSection()}
          </Stack>
        </Box>
        <HeroSection profiles={profile} />
      </header>
    )
  }

  return (
    <header>
      {isLoggedIn ? (
        commonAppBar
      ) : (
        <Box
          sx={{
            backgroundColor: "blueMsGov.main",
            px: 2,
            width: "100%",
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <Stack
            direction={{ xs: "row" }}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              maxWidth: "lg",
              margin: "0 auto",
            }}
          >
            <ImageButton
              onClick={handleLogoClick}
              imageSrc={getAssetsPath() + "/img/logo-ms-gov.svg"}
              alt="logo do governo de Mato Grosso do Sul"
              sx={{
                width: "auto",
                height: { xs: "80px", sm: "90px" },
                padding: "0 0 16px 0",
              }}
              usePriority
            />
            {commonNavbar}
          </Stack>
        </Box>
      )}
    </header>
  )
}
